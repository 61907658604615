<template>
  <div
    class="px-3 md:px-0 container max-w-8xl pt-4 md:pt-10 mx-auto relative"
    v-if="dataFetched"
  >
    <div class="flex gap-2 items-center">
      <div @click.prevent="$router.back()">
        <img :src="require('@/assets/images/experiences/button-back.svg')" />
      </div>
      <div class="text-nColorNavbarPurple text-2xl font-bold mx-2 md:mx-0">
        {{ document.title }}
      </div>
    </div>
    <div v-if="dataFetched" class="mt-4">
      <div class="document-library bg-white rounded-xl">
        <Document :document="document" v-if="document" />
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import Document from "@/components/Experiences/DocumentLibrary/Document.vue";

export default {
  name: "DocumentViewer",
  created() {
    ApiService.get(apiResource.experience.getDocumentDetails, {
      experience_link_id: this.experience_link_id,
      document_id: this.document_id,
    })
      .then(({ data }) => {
        this.document = data.data;
        this.dataFetched = true;
      })
      .catch(() => {});

    ApiService.post(apiResource.obk.markExpAsComplete, {
      exp_link_id: this.experience_link_id,
    })
      .then(() => {})
      .catch(() => {});
  },
  components: {
    Document,
  },
  computed: {},
  data() {
    return {
      document: null,
      dataFetched: false,
      experience_link_id: this.$route.params.experience_link_id,
      document_id: this.$route.params.document_id,
    };
  },
  methods: {},
};
</script>

<style scoped></style>
